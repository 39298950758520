<template>
  <v-container fluid id="data-tables" tag="section">
    <base-material-card
      color="primary"
      icon="mdi-book"
      inline
      class="px-5 py-3"
    >
      <template v-slot:after-heading>
        <v-toolbar flat>
          <v-toolbar-title>{{ title }}</v-toolbar-title>
          <v-spacer />

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            outlined
            dense
            style="max-width: 180px"
            class="mr-5"
          />
          <v-btn
            rounded
            :disabled="selected.length == 0"
            color="black"
            class="mx-2"
            @click="duplicateItem()"
          >
            Duplicate
          </v-btn>
          <dropdown-menu
            title="New"
            :items="links"
            icon="mdi-plus"
          ></dropdown-menu>
        </v-toolbar>
      </template>
      <v-divider class="mt-3" />
      <v-data-table
        class="aq-tbl"
        v-model="selected"
        :headers="headers"
        :items="items"
        :search.sync="search"
        :loading="loadingPage"
        show-select
      >
        <template #[`item.name`]="{ item }">
          <div>
            {{ item.name | upText }}
          </div>
        </template>
        <template #[`item.lock`]="{ item }">
          <div class="text-center" v-if="item.customer_active">
            {{ item.lock ? 'Premium' : 'Free' }}
          </div>
        </template>
        <template #[`item.updated`]="{ item }">
          <div class="text-center">
            <div v-if="item.updated">
              {{ item.updated | formatDate('MMM DD YYYY') }}
              <span v-if="item.update_user_name">
                by {{ item.update_user_name }}
              </span>
            </div>
            <div v-else>
              {{ item.created | formatDate('MMM DD YYYY') }}
              <span v-if="item.add_user_name">by {{ item.add_user_name }}</span>
            </div>
          </div>
        </template>
        <template #[`item.actions`]="{ item }">
          <div v-if="item.editable">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  fab
                  icon
                  x-small
                  v-bind="attrs"
                  v-on="on"
                  @click="duplicateItem(item)"
                >
                  <v-icon small>mdi-content-duplicate</v-icon>
                </v-btn>
              </template>
              <span>Duplicate Play</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  fab
                  icon
                  x-small
                  v-bind="attrs"
                  v-on="on"
                  :to="`/plays/${item.play_type}/${item.id}/edit`"
                >
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Edit Play</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  fab
                  icon
                  x-small
                  v-bind="attrs"
                  v-on="on"
                  @click="deleteItem(item)"
                >
                  <v-icon small>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Delete Play</span>
            </v-tooltip>
          </div>
          <div v-else>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  fab
                  icon
                  x-small
                  v-bind="attrs"
                  v-on="on"
                  @click="copyItem(item)"
                >
                  <v-icon small>mdi-content-copy</v-icon>
                </v-btn>
              </template>
              <span>Copy Play</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  fab
                  icon
                  x-small
                  v-bind="attrs"
                  v-on="on"
                  :to="`/plays/view/${item.id}`"
                >
                  <v-icon small>mdi-eye</v-icon>
                </v-btn>
              </template>
              <span>View Play</span>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
    </base-material-card>

    <!-- confirmation Dialog -->
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="text-left mb-3">
          Are you sure you want to delete this play?
        </v-card-title>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="info"
            rounded
            :loading="loading"
            text
            @click="dialog = false"
          >
            Cancel
          </v-btn>
          <v-btn color="error" rounded :loading="loading" @click="deleteSubmit">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Duplicate Dialog -->
    <v-dialog v-model="dialogDuplicate" width="500">
      <v-card>
        <v-card-title class="text-left mb-3">Duplicate</v-card-title>
        <v-divider />
        <v-card-text>Are you sure you want to duplicate?</v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="info"
            :loading="loading"
            text
            rounded
            @click="dialogDuplicate = false"
          >
            No
          </v-btn>
          <v-btn
            color="error"
            :loading="loading"
            rounded
            v-if="isSelectedDuplicate"
            @click="duplicateSelected"
          >
            Yes
          </v-btn>
          <v-btn
            color="error"
            :loading="loading"
            rounded
            v-else
            @click="duplicateSubmit"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Copy Dialog -->
    <v-dialog v-model="dialogCopy" width="500">
      <v-card>
        <v-card-title class="text-left mb-3">Copy</v-card-title>
        <v-divider />
        <v-card-text>Are you sure you want to copy?</v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="info"
            :loading="loading"
            text
            rounded
            @click="dialogCopy = false"
          >
            No
          </v-btn>
          <v-btn color="error" rounded :loading="loading" @click="copySubmit">
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import generic from '@/mixins/generic.js';
import DropdownMenu from '@/components/base/DropdownMenu';
import { mapState } from 'vuex';
import { playsCollection, usersCollection, storage } from '@/utils/firebase';

export default {
  name: 'PlaysIndex',
  components: { DropdownMenu },
  mixins: [generic],
  data: () => ({
    title: 'Plays',
    dialog: false,
    selectedItem: null,
    items: [],
    search: null,
    selected: [],
    vShowChange: false,
    loading: false,
    isSelectedDelete: false,
    dialogDuplicate: false,
    isSelectedDuplicate: false,
    loadingPage: false,
    plans: [],
    removedPlans: [],
    removedTemplates: [],
    templates: [],
    dialogCopy: false,
    users: {},
    links: [
      { link: '/plays/offence/create', text: 'Offensive play' },
      { link: '/plays/defence/create', text: 'Defensive play' },
      { link: '/plays/special/create', text: 'Special teams play' },
      { link: '/plays/formation/create', text: 'Formation' },
    ],
  }),

  computed: {
    ...mapState({
      user: (state) => state.user.user,
      orgId: (state) => state.user.orgId,
      orgName: (state) => state.user.orgName,
      customerId: (state) => state.user.customerId,
      customerName: (state) => state.user.customerName,
    }),
    headers() {
      let header = [
        { text: 'Name', value: 'name' },
        { text: 'Status', value: 'status', width: '200px' },
        { text: 'Access', value: 'access' },
        { text: 'Updated', value: 'updated', width: '300px' },
        { text: 'Actions', value: 'actions', sortable: false, width: '120px' },
      ];

      if (this.customerId) {
        header = [
          { text: 'Name', value: 'name' },
          { text: 'Status', value: 'status', width: '200px' },
          { text: 'Owner', value: 'owner', width: '200px' },
          { text: 'Updated', value: 'updated', width: '300px' },
          {
            text: 'Actions',
            value: 'actions',
            sortable: false,
            width: '120px',
          },
        ];
      }

      return header;
    },
  },

  created() {
    this.getUsers();
  },

  methods: {
    async getUsers() {
      try {
        let resSnap = usersCollection;
        if (this.orgId != null) {
          resSnap = resSnap.where('org_id', '==', this.orgId);
        }
        resSnap = resSnap.orderBy('name', 'desc');
        let querySnapshot = await resSnap.get();
        if (querySnapshot.docs.length > 0) {
          querySnapshot.forEach((doc) => {
            let post = doc.data();
            let user = {};
            user[doc.id] = post.name;
            this.users = Object.assign(this.users, user);
          });
        }
        this.initialize();
      } catch (err) {
        this.$store.dispatch('notification/add', {
          type: 'error',
          message: `An error occurred : ${err.message}`,
        });
      }
    },
    initialize() {
      this.loadingPage = true;
      this.getPlays();
    },

    async getPlays() {
      try {
        this.items = [];
        let play = playsCollection;
        if (this.orgId != null) {
          play = play.where('org_id', '==', this.orgId);
        }
        await play.orderBy('created', 'desc').onSnapshot((querySnapshot) => {
          let plays = [];
          querySnapshot.forEach((doc) => {
            let data = doc.data();
            data.id = doc.id;
            data.owner = this.orgName;
            data.editable = false;
            data.update_user_name = data.update_user_id
              ? this.users[data.update_user_id]
              : '';
            data.add_user_name = data.add_user_id
              ? this.users[data.add_user_id]
              : '';

            if (this.customerId) {
              if (data.customer_id && data.customer_id === this.customerId) {
                data.editable = true;
                data.owner = this.customerName;
              }

              if (!data.customer_id || data.customer_id === this.customerId)
                plays.push(data);
            } else {
              if (!data.customer_id) {
                data.editable = true;
                plays.push(data);
              }
            }
          });
          this.items = plays;
        });
        this.loadingPage = false;
      } catch (err) {
        this.loadingPage = false;
        this.$store.dispatch('notification/add', {
          type: 'error',
          message: `An error occurred : ${err.message}`,
        });
      }
    },

    deleteItem(item) {
      if (!item || !item.id) {
        this.$store.dispatch('notification/add', {
          type: 'error',
          message: `Wrong item!`,
        });
        return;
      }
      if (this.customerId && !item.customer_id) {
        this.$store.dispatch('notification/add', {
          type: 'error',
          message: `Access denied : You can not delete the play.`,
        });
        return;
      }
      if (
        this.customerId &&
        item.customer_id &&
        this.customerId !== item.customer_id
      ) {
        this.$store.dispatch('notification/add', {
          type: 'error',
          message: `Access denied : You can not delete the play.`,
        });
        return;
      }

      this.selectedItem = item;
      this.dialog = true;
    },

    async deleteSubmit() {
      try {
        this.loading = true;
        await playsCollection.doc(this.selectedItem.id).delete();

        if (this.selectedItem.media_file_name)
          await storage
            .ref(`plays/${this.selectedItem.media_file_name}`)
            .delete();
        if (this.selectedItem.thumb_name)
          await storage.ref(`thumb/${this.selectedItem.thumb_name}`).delete();

        this.dialog = false;
        this.$store.dispatch('notification/add', {
          message: `${this.selectedItem.name} has been deleted`,
          type: 'success',
        });
        this.selectedItem = null;
        this.loading = false;
      } catch (err) {
        this.dialog = false;
        this.loading = false;
        this.$store.dispatch('notification/add', {
          type: 'error',
          message: `An error occurred : ${err.message}`,
        });
      }
    },

    duplicateItem(item) {
      if (item) {
        this.dialogDuplicate = true;
        this.selectedItem = item;
        this.isSelectedDuplicate = false;
      } else {
        this.dialogDuplicate = true;
        this.isSelectedDuplicate = true;
      }
    },

    async duplicateSelected() {
      try {
        this.loading = true;
        await Promise.all(
          this.selected.map(async (row) => {
            delete row.id;
            delete row.update_user_id;
            delete row.updated;
            delete row.editable;
            delete row.add_user_name;
            delete row.owner;

            row.created = new Date();
            row.name = row.name + ' - Duplicated';
            row.changed = false;
            row.add_user_id = this.user.uid;
            await playsCollection.doc().set(row);
          }),
        );
        this.dialogDuplicate = false;
        this.selected = [];
        this.loading = false;
        this.$store.dispatch('notification/add', {
          message: `Selected items have been duplicated`,
          type: 'success',
        });
      } catch (err) {
        this.dialogDuplicate = false;
        this.loading = false;
        this.$store.dispatch('notification/add', {
          type: 'error',
          message: `An error occurred : ${err.message}`,
        });
      }
    },
    async duplicateSubmit() {
      try {
        this.loading = true;
        delete this.selectedItem.id;
        delete this.selectedItem.update_user_id;
        delete this.selectedItem.updated;
        delete this.selectedItem.editable;
        delete this.selectedItem.add_user_name;
        delete this.selectedItem.update_user_name;
        delete this.selectedItem.owner;

        this.selectedItem.created = new Date();
        this.selectedItem.name = this.selectedItem.name + ' - Duplicated';
        this.selectedItem.media_file_name = null;
        this.selectedItem.media_url = null;
        this.selectedItem.thumb_url = null;
        this.selectedItem.thumb_name = null;
        this.selectedItem.media_type = null;
        this.selectedItem.changed = false;
        this.selectedItem.add_user_id = this.user.uid;
        await playsCollection.doc().set(this.selectedItem);

        this.dialogDuplicate = false;
        this.$store.dispatch('notification/add', {
          message: `${this.selectedItem.name} has been duplicated`,
          type: 'success',
        });
        this.selectedItem = null;
        this.loading = false;
      } catch (err) {
        this.dialogDuplicate = false;
        this.loading = false;
        this.$store.dispatch('notification/add', {
          type: 'error',
          message: `An error occurred : ${err.message}`,
        });
      }
    },

    copyItem(item) {
      this.dialogCopy = true;
      this.selectedItem = item;
    },
    async copySubmit(item) {
      try {
        this.loading = true;
        delete this.selectedItem.id;
        delete this.selectedItem.update_user_id;
        delete this.selectedItem.updated;
        delete this.selectedItem.editable;
        delete this.selectedItem.update_user_name;
        delete this.selectedItem.add_user_name;
        delete this.selectedItem.owner;

        this.selectedItem.created = new Date();
        this.selectedItem.name = this.selectedItem.name + ' - Copied';
        this.selectedItem.changed = false;
        this.selectedItem.add_user_id = this.user.uid;
        this.selectedItem.customer_id = this.customerId || null;
        this.selectedItem.customer_name = this.customerName || null;
        await playsCollection.doc().set(this.selectedItem);

        this.dialogCopy = false;
        this.$store.dispatch('notification/add', {
          message: `${this.selectedItem.name} has been copied`,
          type: 'success',
        });
        this.selectedItem = null;
        this.loading = false;
      } catch (err) {
        this.dialogCopy = false;
        this.loading = false;
        this.$store.dispatch('notification/add', {
          type: 'error',
          message: `An error occurred : ${err.message}`,
        });
      }
    },
  },
};
</script>
